import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of test week`}</em></p>
    <p>{`Max HS Walk, 3 attempts`}</p>
    <p>{`Max Height Box Jump 4×1 to 1RM`}</p>
    <p>{`Max Deadlift 4×1 to 1RM`}</p>
    <p>{`then, `}<strong parentName="p">{`Open WOD 14.3`}</strong></p>
    <p><strong parentName="p">{`MEN `}<em parentName="strong">{`— includes Masters Men up to 54 years old`}</em></strong>{`\\
Complete as many reps as possible in 8 minutes of:\\
135-lb. deadlifts, 10 reps\\
15 box jumps, 24-inch\\
185-lb. deadlifts, 15 reps\\
15 box jumps, 24-inch\\
225-lb. deadlifts, 20 reps\\
15 box jumps, 24-inch\\
275-lb. deadlifts, 25 reps\\
15 box jumps, 24-inch\\
315-lb. deadlifts, 30 reps\\
15 box jumps, 24-inch\\
365-lb. deadlifts, 35 reps\\
15 box jumps, 24-inch`}</p>
    <p><strong parentName="p">{`WOMEN `}<em parentName="strong">{`— includes Masters Women up to 54 years old`}</em></strong>{`\\
Complete as many reps as possible in 8 minutes of:\\
95-lb. deadlifts, 10 reps\\
15 box jumps, 20-inch\\
135-lb. deadlifts, 15 reps\\
15 box jumps, 20-inch\\
155-lb. deadlifts, 20 reps\\
15 box jumps, 20-inch\\
185-lb. deadlifts, 25 reps\\
15 box jumps, 20-inch\\
205-lb. deadlifts, 30 reps\\
15 box jumps, 20-inch\\
225-lb. deadlifts, 35 reps\\
15 box jumps, 20-inch`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  Get your
teams together and registered soon to reserve your spot!  December 17th
is the last day to register and get your Granite Games shirt on the day
of the competition. If you’d like to be on a team but don’t have 2 other
teammates please put your name on the white board in back and we’ll get
you on a team. `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      